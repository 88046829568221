@import url(https://fonts.googleapis.com/css2?family=Open+Sans&family=Work+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.discord-embed .discord-embed-field .discord-field-title {
  color: white !important;
}

.discord-messages {
  position: relative;
  bottom: 0;
}

.spoiler {
  color: black;
  background: black;
  cursor: pointer;
}

.spoiler-revealed {
  color: #dcddde !important;
  background: none !important;
  cursor: auto;
}

.mac-circle {
  height: 10px;
  width: 10px;
  border-radius: 5px;
  display: inline-block;
  margin-right: 5px;
}

.bg-danger {
  background-color: #f55753;
}

.bg-warning {
  background-color: #f8d053;
}

.bg-success {
  background-color: #28a745;
}

.App {
  max-width: 1024px;
  margin: 0 auto;
}

